import React, { useEffect, useState } from 'react';

import './PatientsList.css';
import AppDataTable from '../../components/app-table/AppDataTable';
import { FilterElementEnum, TableActionEnum, appIcons, fieldName, labelText } from '../../utils/text-constants';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog/Dialog';
import { Card } from 'primereact/card';
import { addPatient, deletePatient, editPatient, getAllPatients1 } from '../../api/patient.api';
import { successAlert } from '../../utils/alerts/alert';


const PatientsList = () => {

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<any>('');
    const [patientsData, setPatientsData] = useState<any>();
    const [patientsDataCopy, setPatientsDataCopy] = useState<any>();

    useEffect(() => {
        getAllPatients();
    }, [])


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        gender: 'male',
        dateOfBirth: '',
        contactInformation: {
            address: '',
            phoneNumber: '',
            email: '',
        },
        dentalInsuranceProvider: '',
        policyHolderName: '',
        policyHolderID: '',
    });


    const onRowClick = (rowData: any) => {
        // alert('Row clicked')
    }

    const onPatientEdit1 = async (rowData: any) => {
        setIsVisible(true);
        setIsEdit(true);
        setEditId(rowData._id);
        setFormData({
            firstName: rowData.firstName,
            lastName: rowData.lastName,
            gender: rowData.gender,
            dateOfBirth: rowData.dateOfBirth,
            contactInformation: {
                address: rowData.contactInformation.address,
                phoneNumber: rowData.contactInformation.phoneNumber,
                email: rowData.contactInformation.email,
            },
            dentalInsuranceProvider: rowData.dentalInsuranceProvider,
            policyHolderName: rowData.policyHolderName,
            policyHolderID: rowData.policyHolderID,
        }
        )
    }

    const getAllPatients = async () => {
        await getAllPatients1().then((response: any) => {
            const data = response?.data.map((staff: any) => ({
                ...staff,
                Name: `${staff.firstName} ${staff.lastName}`,
            }));
            setPatientsData(data);
            setPatientsDataCopy(data);
        }).catch((err: any) => {
            console.log('get patients error', err);
        })
    }

    const onPatientDelete = async (rowData: any) => {
        await deletePatient(rowData._id).then((response: any) => {
            getAllPatients();
            successAlert({ title: 'Success', text: `Patient deleted successfully.`, icon: 'success' });
        }).catch((err: any) => {
            console.log('error in deleting staff', err);
        })
    }

    //table congig
    const tableConfig: any = {
        onRowClick: onRowClick,
        columnConfig: [

            {
                columnName: 'Name',
                columnHeader: 'Patient Name',
                isSortable: true,
                isFilterable: true,
                filterPlaceholder: 'Enter patient name',
                filterType: FilterElementEnum.InputText,
                filterOptions: '',
                filterOptionLabel: '',
                filterOptionValue: '',
            },
            {
                columnName: 'gender',
                columnHeader: 'Gender',
                isSortable: true,
                isFilterable: true,
                filterPlaceholder: 'Enter Gender',
                filterType: FilterElementEnum.InputText,
                filterOptions: '',
                filterOptionLabel: '',
                filterOptionValue: '',
            },
            {
                columnName: fieldName.actions,
                columnHeader: labelText.actions,
                isSortable: false,
                isFilterable: false,
                actions: [
                    { action: TableActionEnum.Edit, actionMethod: onPatientEdit1, actionIcon: appIcons.edit, tooltip: labelText.edit },
                    { action: TableActionEnum.Archive, actionMethod: onPatientDelete, actionIcon: appIcons.delete, tooltip: labelText.delete },
                ]
            },
        ]
    };

    const onPatientAdd = async () => {
        if (!isEdit) {
            await addPatient(formData).then((response: any) => {
                setIsVisible(false);
                successAlert({ title: 'Success', text: `Patient added successfully.`, icon: 'success' })
                setFormData({
                    firstName: '',
                    lastName: '',
                    gender: 'male',
                    dateOfBirth: '',
                    contactInformation: {
                        address: '',
                        phoneNumber: '',
                        email: '',
                    },
                    dentalInsuranceProvider: '',
                    policyHolderName: '',
                    policyHolderID: '',
                }
                )
                getAllPatients();
            }).catch((err: any) => {
                console.log('patient add error', err)
            })
        } else {
            await editPatient(formData, editId).then((response: any) => {
                successAlert({ title: 'Success', text: `Patient edited successfully.`, icon: 'success' })
                getAllPatients();
                setIsVisible(false);
                setFormData({
                    firstName: '',
                    lastName: '',
                    gender: 'male',
                    dateOfBirth: '',
                    contactInformation: {
                        address: '',
                        phoneNumber: '',
                        email: '',
                    },
                    dentalInsuranceProvider: '',
                    policyHolderName: '',
                    policyHolderID: '',
                }
                )
            }).catch((err: any) => {
                console.log('edit patient error', err)
            })
        }
    }

    const handleChange = (e: any) => {
        const filteredData = patientsDataCopy.filter((item: any) => {
            // Customize this condition based on your requirements
            return Object.values(item).some((value: any) =>
                value.toString().toLowerCase().includes(e.target.value.toLowerCase())
            );
        });

        setPatientsData(filteredData);
    }

    const handleChange1 = (e: any) => {
        const { name, value } = e.target;

        if (name.includes('contactInformation')) {
            // Update nested contactInformation property
            setFormData((prevData) => ({
                ...prevData,
                contactInformation: {
                    ...prevData.contactInformation,
                    [name.split('.')[1]]: value,
                },
            }));
        } else {
            // Update other properties
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    return (

        <section className="page-content">
            {/* Add Patient Dialog  start*/}
            <Dialog
                header={isEdit ? "Editing a Patient" : "Adding a new Patient"}
                visible={isVisible}
                onHide={() => { setIsVisible(false); setIsEdit(false) }}
                handleCancel={() => { setIsVisible(false); setIsEdit(false) }}
                handleFooterSubmit={() => onPatientAdd()}
            >
                <div className="form-container">
                    <form>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name:</label>
                            <input
                                type="text"
                                id="firstName"
                                placeholder='Enter first name'
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange1}
                                className="input-field"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastName">Last Name:</label>
                            <input
                                type="text"
                                id="lastName"
                                placeholder='Enter last name'
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange1}
                                className="input-field"
                            />
                        </div>

                        <div className="form-group gender-group">
                            <label>Gender:</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="male"
                                        checked={formData.gender === 'male'}
                                        onChange={handleChange1}
                                    />
                                    Male
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="female"
                                        checked={formData.gender === 'female'}
                                        onChange={handleChange1}
                                    />
                                    Female
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="others"
                                        checked={formData.gender === 'others'}
                                        onChange={handleChange1}
                                    />
                                    Others
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="dob">Date of Birth:</label>
                            <input
                                type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                value={formData.dateOfBirth}
                                onChange={handleChange1}
                                placeholder="YYYY-MM-DD"
                                className="input-field"
                            />
                        </div>

                        <div className="form-group">
                            <label>Contact Information:</label>
                            <div>
                                <label htmlFor="address">Address:</label>
                                <input
                                    type="text"
                                    id="address"
                                    placeholder='Enter address'
                                    name="contactInformation.address"
                                    value={formData.contactInformation.address}
                                    onChange={(e) => handleChange1(e)}
                                    className="input-field"
                                />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    placeholder='Enter phone number'
                                    name="contactInformation.phoneNumber"
                                    value={formData.contactInformation.phoneNumber}
                                    onChange={handleChange1}
                                    className="input-field"
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="contactInformation.email"
                                    value={formData.contactInformation.email}
                                    onChange={handleChange1}
                                    className="input-field"
                                    placeholder='Enter email address'
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="dentalInsuranceProvider">Dental Insurance Provider:</label>
                            <select
                                id="dentalInsuranceProvider"
                                name="dentalInsuranceProvider"
                                value={formData.dentalInsuranceProvider}
                                onChange={handleChange1}
                                className="input-field"
                            >
                                <option value="" disabled>Select Provider</option>
                                <option value="provider1">Provider 1</option>
                                <option value="provider2">Provider 2</option>
                                <option value="provider3">Provider 3</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="policyHolderName">Policy Holder Name:</label>
                            <input
                                type="text"
                                id="policyHolderName"
                                name="policyHolderName"
                                value={formData.policyHolderName}
                                onChange={handleChange1}
                                className="input-field"
                                placeholder='Enter policy holder name'
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="policyHolderId">Policy Holder ID:</label>
                            <input
                                type="text"
                                id="policyHolderID"
                                name="policyHolderID"
                                value={formData.policyHolderID}
                                onChange={handleChange1}
                                className="input-field"
                                placeholder='Enter policy holder id'
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add Patient Dialog end */}

            <div className="layout-content">
                <div className="d-flex justify-content-between p-3">
                    <span className="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
                        {/* <i className="pi pi-search pt-1"></i>
                            <input
                                className="p-inputtext p-component w-full w-full"
                                placeholder="Search Patient"
                                data-pc-name="inputtext"
                                data-pc-section="root"
                                onChange={(e: any) => handleChange(e)}
                            /> */}
                    </span>
                    <Button
                        label="Add Patient"
                        icon="pi pi-user-plus"
                        onClick={() => setIsVisible(true)}
                        style={{
                            backgroundColor: "#87dfd6",
                            border: "1px solid #87dfd6",
                            color: "#000", fontWeight: 600
                        }}
                        severity="success"
                    />
                </div>
                <div
                    className="p-datatable p-component p-datatable-responsive-scroll"
                    data-scrollselectors=".p-datatable-wrapper"
                    data-pc-name="datatable"
                    data-pc-section="root"
                >

                </div>
                <Card title="Patients List" className='patient-card'>
                    <div className='card-body'>
                        <AppDataTable inputTableConfig={tableConfig} inputData={patientsData} />
                    </div>
                </Card>
            </div>
        </section>

    )
}

export default PatientsList;
