import Swal from 'sweetalert2';

import './alert.css';

export const successAlert = ({ title, text, icon }: any) => {

    return (
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: 'OK',
            allowOutsideClick: false
        })
    )
}