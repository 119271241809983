export const genericText={
    classSeparator: ' ',
}

export const fieldName = {
    actions: 'actions'
}

export enum FilterElementEnum {
    InputText,
    DropDown,
}

export enum TableActionEnum {
    Activate = "Activate",
    Deactivate = "Deactivate",
    Edit = "Edit",
    Delete = "Delete",
    Reset = "Reset",
    View = "View",
    Archive = "Archive",
}

export const appIcons = {
    edit: 'bi bi-pencil',
    delete: 'bi bi-trash'
}

export const labelText = {
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete'
}