import { Navigate, useLocation } from "react-router-dom";
import { isValidString, isValidValue } from "../utils/common-functions";

type Props = {
    children: JSX.Element
}

const ProtectedRoutes: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    let accessToken = localStorage.getItem('token')
    if (!isValidString(accessToken)) {
        return <Navigate to={'/dashboard'} replace />;
    }

    return children;
}

export default ProtectedRoutes;