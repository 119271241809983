import React, { useState, useEffect } from 'react';
import Loading from "../../../assets/images/Loader.gif"
import "../loader/loader.components.css"
const Loader: React.FC = ({}) => {
    return (

        <div className="ds-loading-overlay ds-component-overlay">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem',color: '#36a41d'}}></i>
        {/* <img src={Loading} style={{ width: '100px', height:'50px',marginLeft: "301px" }} alt="loading"/> */}
        </div>
        );
    };
    
    export default Loader;