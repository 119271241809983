import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Loginnew from "../../pages/login/login";
import Dashboard from "../../pages/dashboard/dashboard";
import { isValidString } from '../../utils/common-functions';
import ProtectedRoutes from '../ProtectedRoutes';
import PatientsList from '../../pages/patients/PatientsList';
import Invoice from '../../pages/invoice/Invoice';
import AppointmentsCalender from '../../pages/appointments/AppointmentsCalender';
import DentalChart from '../../pages/dentalchart/DentalChart';
import Staff from '../../pages/staff/Staff';
import Layout from '../layout/Layout';
import Profile from '../../pages/profile/Profile';
import Commingsoon from "../../pages/comingsoon/comingsoon.page";
import Home from '../../pages/home/home.page';
import Statistics from '../../pages/statistics/Statistics.page';
import Chatbotnew from '../../pages/chatbot/ChatbotNew';


export const AppRouter: React.FC = () => {
  // const isAuthenticated = !! localStorage.getItem("accessToken");
  const isAuthenticated = isValidString(localStorage.getItem('token'));


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"
          element={<Home />}
        />
        <Route path="/chat"
          element={<Chatbotnew />}
        />
        <Route path="login"
          element={<Loginnew />}
        />
        <Route path="/" element={<ProtectedRoutes><Layout /></ProtectedRoutes>}>
          <Route path="dashboard"
            element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>}
          />
          <Route path="patients-list"
            element={<PatientsList />}
          />
          <Route path="invoice-generation"
            element={<Invoice />}
          />
          <Route path="appointment-calender"
            element={<AppointmentsCalender />}
          />
          <Route path="staff-management"
            element={<Staff />}
          />
          <Route path="dental-charting"
            element={<Commingsoon />}
          />
          <Route path="profile"
            element={<Profile />}
          />
          <Route path="statistics"
            element={<Statistics />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );

}