import React from 'react';

import './Layout.css';
import Navbar from '../ui/navbar/sidenav.components';
import { Outlet } from 'react-router-dom';
import Header from '../ui/header/Header';

const Layout = () => {

    return (
        <div className='d-flex'>
            <div className='col-2'>
                <Navbar />
            </div>
            <div className='col'>
                <Outlet />
            </div>
        </div>
    )
}

export default Layout;
