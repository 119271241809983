import HttpInstance from "./http.api";
import jwt_decode from "jwt-decode";

export const addPatient = (data: any) => {
    return HttpInstance.post('/patients', data);
}

export const getAllPatients1 = () => {
    return HttpInstance.get('/patients');
}

export const editPatient = (data: any, id: any) => {
    return HttpInstance.patch('/patients/' + id, data);
}

export const deletePatient = (id: any) => {
    return HttpInstance.delete(`/patients/` + id);
}