export const tabledata = 
[   
        {
            "id": "1000",
            "time": "Today",
            "visitors": 42,
            "visits": 109
        },
        {
            "id": "1000",
            "time": "Yesterday",
            "visitors": 352,
            "visits": 461
        },
        {
            "id": "1000",
            "time": "last week",
            "visitors": 2000,
            "visits": 4321
        },
        {
            "id": "1000",
            "time": "Last 30 days",
            "visitors": 7543,
            "visits": 12344
        },
        {
            "id": "1000",
            "time": "Last 60 days",
            "visitors": 11000,
            "visits": 19343
        },
        {
            "id": "1000",
            "time": "last 12 months",
            "visitors": 63103,
            "visits": 151233
        },
        {
            "id": "1000",
            "time": "This year (Jan-Today)",
            "visitors": 10000,
            "visits": 18433
        },
        {
            "id": "1000",
            "time": "Last year",
            "visitors": 50000,
            "visits": 114233
        }
]

export const toppagedata = [
    { id: 1, Title: '/home', link: 'https://example.com/home', Visits: 1000 },
    { id: 2, Title: '/about', link: 'https://example.com/about', Visits: 800 },
    { id: 3, Title: '/products', link: 'https://example.com/products', Visits: 1200 },
    { id: 4, Title: '/contact', link: 'https://example.com/contact', Visits: 500 },
    { id: 5, Title: '/services', link: 'https://example.com/services', Visits: 1500 },
    { id: 6, Title: '/blog', link: 'https://example.com/blog', Visits: 900 },
    { id: 7, Title: '/gallery', link: 'https://example.com/gallery', Visits: 600 },
    { id: 8, Title: '/faq', link: 'https://example.com/faq', Visits: 300 },
    { id: 9, Title: '/pricing', link: 'https://example.com/pricing', Visits: 2000 },
    { id: 10, Title: '/terms', link: 'https://example.com/terms', Visits: 700 },
    // Add more data as needed
  ];

export const topCountriesData = [
    { id: 1, country: 'USA', visitors: 1000 },
    { id: 2, country: 'India', visitors: 800 },
    { id: 3, country: 'China', visitors: 1200 },
    { id: 4, country: 'Brazil', visitors: 500 },
    { id: 5, country: 'Japan', visitors: 1500 },
    { id: 6, country: 'Russia', visitors: 900 },
    { id: 7, country: 'Germany', visitors: 600 },
    { id: 8, country: 'France', visitors: 300 },
    { id: 9, country: 'UK', visitors: 2000 },
    { id: 10, country: 'Australia', visitors: 700 },
    // Add more data as needed
  ];
  
  export const topvisitorsData = [
    { id: 1, visitors: 1000, country: 'USA', city: 'New York', ipbrowser: '192.168.1.1', platform: 'Windows' },
    { id: 2, visitors: 800, country: 'India', city: 'Delhi', ipbrowser: '192.168.1.2', platform: 'Android' },
    { id: 3, visitors: 1200, country: 'China', city: 'Shanghai', ipbrowser: '192.168.1.3', platform: 'iOS' },
    { id: 4, visitors: 500, country: 'Brazil', city: 'Rio de Janeiro', ipbrowser: '192.168.1.4', platform: 'Linux' },
    { id: 5, visitors: 1500, country: 'Japan', city: 'Tokyo', ipbrowser: '192.168.1.5', platform: 'Windows' },
    { id: 6, visitors: 900, country: 'Russia', city: 'Moscow', ipbrowser: '192.168.1.6', platform: 'iOS' },
    { id: 7, visitors: 600, country: 'Germany', city: 'Berlin', ipbrowser: '192.168.1.7', platform: 'Windows' },
    { id: 8, visitors: 300, country: 'France', city: 'Paris', ipbrowser: '192.168.1.8', platform: 'Android' },
    { id: 9, visitors: 2000, country: 'UK', city: 'London', ipbrowser: '192.168.1.9', platform: 'Windows' },
    { id: 10, visitors: 700, country: 'Australia', city: 'Sydney', ipbrowser: '192.168.1.10', platform: 'iOS' },
    // Add more data as needed
  ];
  interface MapVisitorData {
    id: number;
    visitors: number;
    country: string;
    city: string;
    latitude: number;
    longitude: number;
  }
  export const mapvisitorsData: MapVisitorData[] = [
    {
      id: 1,
      visitors: 1000,
      country: 'USA',
      city: 'New York',
      latitude: 40.7128,
      longitude: -74.006,
    },
    {
      id: 2,
      visitors: 800,
      country: 'India',
      city: 'Delhi',
      latitude: 28.6139,
      longitude: 77.209,
    },
    {
      id: 3,
      visitors: 1200,
      country: 'China',
      city: 'Shanghai',
      latitude: 31.2304,
      longitude: 121.4737,
    },
    {
      id: 4,
      visitors: 500,
      country: 'Brazil',
      city: 'Rio de Janeiro',
      latitude: -22.9068,
      longitude: -43.1729,
    },
    {
      id: 5,
      visitors: 1500,
      country: 'Japan',
      city: 'Tokyo',
      latitude: 35.6895,
      longitude: 139.6917,
    },
    {
      id: 6,
      visitors: 900,
      country: 'Russia',
      city: 'Moscow',
      latitude: 55.7558,
      longitude: 37.6176,
    },
    {
      id: 7,
      visitors: 600,
      country: 'Germany',
      city: 'Berlin',
      latitude: 52.5200,
      longitude: 13.4050,
    },
    {
      id: 8,
      visitors: 300,
      country: 'France',
      city: 'Paris',
      latitude: 48.8566,
      longitude: 2.3522,
    },
    {
      id: 9,
      visitors: 2000,
      country: 'UK',
      city: 'London',
      latitude: 51.5074,
      longitude: -0.1278,
    },
    {
      id: 10,
      visitors: 700,
      country: 'Australia',
      city: 'Sydney',
      latitude: -33.8688,
      longitude: 151.2093,
    },
  ];