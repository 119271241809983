// Profile.js
import React, { useEffect, useState } from 'react';
import './Profile.css';
import { getProfileData } from '../../api/profile.api';
import doctorImage from '../../assets/images/doctor2.jpg';
import { Card } from 'primereact/card';

const Profile = () => {
  const [profileData, setProfileData] = useState<any>(null);

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      const response = await getProfileData(localStorage.getItem('id'));
      setProfileData(response.data);
    } catch (error) {
      console.error('Profile error:', error);
    }
  };

  return (
    <div className="profile-container">
      <div className='row'>
      <Card className="profile-card">
        <div className="profile-header">
          <img src="https://img.freepik.com/free-photo/young-female-patient-visiting-dentist-office_496169-930.jpg" alt="doctor-pic" className="profile-image" />
          <div className="profile-details">
            <h2 className="profile-name">{`${profileData?.firstName} ${profileData?.lastName}`}</h2>
            <p className="profile-username">@{profileData?.username}</p>
            <p className="profile-rating">Experience: <span>{profileData?.experience} years</span></p>
            <div className="profile-section">
              <p className="profile-text">{profileData?.contactInformation.officeAddress}</p>
              <p className="profile-text">
                <span className="profile-icon">&#9742;</span> {profileData?.contactInformation.phoneNumber}
              </p>
            </div>
          </div>
        </div>

    </Card>
    <Card className="profile-card">
        <div className="profile-section">
          <h3 className="profile-section-title">Professional Details</h3>
          <p className="profile-text">{profileData?.specialization}</p>
          <p className="profile-text">License Number: {profileData?.licenseNumber}</p>
          <p className="profile-text">{profileData?.workHistory}</p>
          <p className="profile-text">Work Schedule: {profileData?.workSchedule}</p>
          <p className="profile-text">Clinic Role: {profileData?.clinicRole}</p>
          <p className="profile-text">Clinic ID: {profileData?.clinicID}</p>
        </div>
        </Card>
    <Card className="profile-card">
  <>
        <div className="profile-section">
          <h3 className="profile-section-title">Certifications</h3>
          <ul className="profile-list">
            {profileData?.certifications.map((certification: any, index: any) => (
              <li key={index} className="profile-list-item">{certification}</li>
            ))}
          </ul>
        </div>
        <br></br>

        <div className="profile-section">
          <h3 className="profile-section-title">Clinic Affiliations</h3>
          <ul className="profile-list">
            {profileData?.clinicAffiliations.map((clinic: any, index: any) => (
              <li key={index} className="profile-list-item">{clinic}</li>
            ))}
          </ul>
        </div>
        <br></br>
        <div className="profile-section">
          <h3 className="profile-section-title">Professional Statement</h3>
          <p className="profile-text">{profileData?.professionalStatement}</p>
        </div>
        </>
      </Card>
    </div>
    </div>
  );
};

export default Profile;