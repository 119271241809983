import * as React from 'react';
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  EditRecurrenceMenu,
  AppointmentForm,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  ViewState,
  EditingState,
  IntegratedEditing,
  ChangeSet,
} from '@devexpress/dx-react-scheduler';

const resources = [
  {
    fieldName: 'resourceId',
    title: 'Doctor',
    instances: [
      { id: 1, text: 'Doctor A' },
      { id: 2, text: 'Doctor B' },
      // Add more resources here
    ],
  },
];

const AppointmentsCalender: React.FC = () => {
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>(null);

  const handleAppointmentClick = (appointment: any) => {
    setSelectedAppointment(appointment);
  };

  const closeAppointmentModal = () => {
    setSelectedAppointment(null);
  };
  const commitChanges = ({ added, changed, deleted }: ChangeSet) => {
    let data: any = schedulerData.slice();

    if (added) {
      const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
      data = [...data, { id: startingAddedId, ...added }];
    }

    if (changed) {
      data = data.map((appointment: any) =>
        changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment
      );
    }

    if (deleted !== undefined) {
      data = data.filter((appointment: any) => appointment.id !== deleted);
    }

    setSchedulerData(data);
  };

  const [schedulerData, setSchedulerData] = React.useState<any>([
    {
      id: 1,
      title: 'John Doe - Annual Checkup',
      startDate: new Date(2023, 10, 6, 9, 0),
      endDate: new Date(2023, 10, 6, 10, 0),
      resourceId: 1,
      color: 'red',
      rRule: 'FREQ=WEEKLY;COUNT=10',
    },
    {
      id: 2,
      title: 'Jane Smith - Toothache',
      startDate: new Date(2023, 10, 7, 10, 30),
      endDate: new Date(2023, 10, 7, 11, 30),
      resourceId: 2,
    },
    // Add more events for different people/resources here
  ]);

  return (
    <div className="page-content">
      <h1>Patient Appointment Scheduler</h1>
      <Scheduler data={schedulerData} height={600} >
        <ViewState defaultCurrentDate={new Date(2023, 10, 6)} />
        <WeekView startDayHour={8} endDayHour={18} />
        <Appointments
          // appointmentComponent={(props: any) => {
          //   const { data, ...restProps } = props;
          //   return (
          //     <Appointments.Appointment
          //       {...restProps}
          //       style={{ backgroundColor: 'green' }}
          //     />
          //   );
          // }}
        />
        <EditingState onCommitChanges={commitChanges} />
        <IntegratedEditing />
        <EditRecurrenceMenu />
        <AppointmentTooltip showOpenButton showCloseButton showDeleteButton />
        <Toolbar />
        <DateNavigator />
        <AppointmentForm />
      </Scheduler>

      {selectedAppointment && (
        <div className="modal">
          <div className="modal-content">
            <h2>Appointment Details</h2>
            <p>{selectedAppointment.title}</p>
            <p>
              {selectedAppointment.startDate.toLocaleString()} -{' '}
              {selectedAppointment.endDate.toLocaleString()}
            </p>
            <button onClick={closeAppointmentModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentsCalender;