import { Dialog } from 'primereact/dialog';

import './Dialog.css';
import { Button } from 'primereact/button';

const DialogModal: React.FC<any> = ({ header, visible, onHide, handleCancel, handleFooterSubmit, children }) => {

    const footerContent = (
        <div className="ps-3 d-flex justify-content-end">
            <Button
                type='button'
                icon={'bi bi-x'}
                label={'Cancel'}
                style={{
                    backgroundColor: "white",
                    border: "1px solid #87dfd6",
                    color: "#87dfd6", fontWeight: 600
                }}
                severity="success"
                onClick={handleCancel}
            />
            <Button
                type='submit'
                icon={'bi bi-check'}
                label={'Save'}
                style={{
                    backgroundColor: "#87dfd6",
                    border: "1px solid #87dfd6",
                    color: "#000", fontWeight: 600
                }}
                onClick={() => { handleFooterSubmit() }}
            />
        </div>
    );

    return (
        <>
            <Dialog
                header={header}
                visible={visible}
                onHide={onHide}
                footer={footerContent}
                headerClassName='text-center'
                className='dialog-modal'
            >
                {children}
            </Dialog>
        </>
    )
}

export default DialogModal;