import React from "react";

import "./Invoice.css";
import logo_light from "../../assets/images/logo_light.svg";


const Invoice = () => {
  return (
<div className="layout-content">
   <div className="card py-8 px-6 md:px-8 overflow-auto">
   <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
              <span className="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
              </span>
              <button
                aria-label="Add New"
                className="p-button p-component w-full sm:w-auto flex-order-0 sm:flex-order-1 p-button-outlined"
                type="button"
                data-pc-name="button"
                data-pc-section="root"
              >
                <span
                  className="p-button-icon p-c p-button-icon-left pi pi-file"
                  data-pc-section="icon"
                ></span>
                <span className="p-button-label p-c" data-pc-section="label">
                  Generate Invoice
                </span>
              </button>
            </div>
      <div className="flex flex-column align-items-start md:flex-row md:align-items-center md:justify-content-between border-bottom-1 surface-border pb-5 min-w-max">
         <div className="flex flex-column">
        <img src={logo_light} className="flex-shrink-0 mb-1 invoice-logo" alt="product"/>

            <div className="my-3 text-4xl font-bold text-900">Dental Suite</div>
            <span className="mb-2">9137 3rd Lane California City</span><span>CA 93504, U.S.A.</span>
         </div>
         <div className="flex flex-column mt-5 md:mt-0">
            <div className="text-2xl font-semibold text-left md:text-right mb-3">INVOICE</div>
            <div className="flex flex-column">
               <div className="flex justify-content-between align-items-center mb-2"><span className="font-semibold mr-6">DATE</span><span>04/11/2023</span></div>
               <div className="flex justify-content-between align-items-center mb-2"><span className="font-semibold mr-6">INVOICE #</span><span>8523</span></div>
               <div className="flex justify-content-between align-items-center"><span className="font-semibold mr-6">CUSTOMER ID</span><span>C1613</span></div>
            </div>
         </div>
      </div>
      <div className="mt-5 mb-8 flex flex-column">
         <div className="mb-3 text-2xl font-medium">BILL TO</div>
         <span className="mb-2">Claire Williams, 148 Hope Lane</span><span>Palo Alto, CA 94304.</span>
      </div>
      <div className="overflow-x-auto">
         <table className="w-full" style={{borderCollapse: "collapse", tableLayout: "auto"}}>
            <thead>
               <tr>
                  <th className="text-left font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">Description</th>
                  <th className="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">Quantity</th>
                  <th className="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">Unit Price</th>
                  <th className="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">Line Total</th>
               </tr>
            </thead>
            <tbody>
            <tr>
                <td className="text-left py-3 border-bottom-1 surface-border white-space-nowrap">Tooth Extraction</td>
                <td className="text-right py-3 border-bottom-1 surface-border px-3">1</td>
                <td className="text-right py-3 border-bottom-1 surface-border px-3">$123.00</td>
                <td className="text-right py-3 border-bottom-1 surface-border">$123.00</td>
                </tr>
                <tr>
                <td className="text-left py-3 border-bottom-1 surface-border white-space-nowrap">Dental Cleaning</td>
                <td className="text-right py-3 border-bottom-1 surface-border px-3">2</td>
                <td className="text-right py-3 border-bottom-1 surface-border px-3">$200.00</td>
                <td className="text-right py-3 border-bottom-1 surface-border">$400.00</td>
                </tr>
                <tr>
                <td className="text-left py-3 border-bottom-1 surface-border white-space-nowrap">Root Canal</td>
                <td className="text-right py-3 border-bottom-1 surface-border px-3">1</td>
                <td className="text-right py-3 border-bottom-1 surface-border px-3">$350.00</td>
                <td className="text-right py-3 border-bottom-1 surface-border">$350.00</td>
                </tr>

            </tbody>
         </table>
      </div>
      <div className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-8">
         <div className="font-semibold mb-3 md:mb-0">NOTES</div>
         <div className="flex flex-column">
            <div className="flex justify-content-between align-items-center mb-2"><span className="font-semibold mr-6">SUBTOTAL</span><span>$332.00</span></div>
            <div className="flex justify-content-between align-items-center mb-2"><span className="font-semibold mr-6">VAT #</span><span>0</span></div>
            <div className="flex justify-content-between align-items-center"><span className="font-semibold mr-6">TOTAL</span><span>$332.00</span></div>
         </div>
      </div>
   </div>
</div>
  );
};

export default Invoice;
