import HttpInstance from "./http.api";

export const addStaff = (data: any) => {
    return HttpInstance.post('/staff', data);
}

export const getAllStaff = () => {
    return HttpInstance.get('/staff');
}

export const editStaff = (data: any, id: any) => {
    return HttpInstance.patch('/staff/' + id, data);
}

export const deleteStaff = (id: any) => {
    return HttpInstance.delete('/staff/' + id);
}