import React, { useEffect, useState } from "react";
import * as THREE from "three";

import "./ChatbotNew.css";
import logo from "../../assets/images/logo.svg";
import logo_light from "../../assets/images/logo_light.svg";
import BrainNerves from "./brainnerve";
import MyCanvasComponent from "./brainnerve";
const Chatbotnew = () => {

  return (
    <div
      className="bg-midnightDark min-h-screen relative font-gtAmerica text-offBlue text-lg"
      style={{ opacity: 1 }}
    >
      <div className="sticky w-full top-0 bg-midnightDark border-b-[0.5px] border-offBlue/25 z-50">
        <div className="text-center w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-gtAlpinaCondenseLight h-5 lg:h-7 opacity-0 opacity-100 transition-all duration-500 ease-in-out">
          <img
            alt="DentalChat Logo"
            loading="lazy"
            decoding="async"
            data-nimg="fill"
            src={logo_light}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              inset: "0px",
              color: "transparent",
            }}
          />
        </div>
        <div className="relative flex justify-between items-center max-w-[1344px] px-6 sm:px-16 2xl:px-0 mx-auto h-24">
          <a
            className="flex py-4 md:mr-10 lg:mr-20 group items-center"
            href="/"
          >
            <div className="h-10 w-16">
              {/* <img
                alt="Dental Logo"
                loading="lazy"
                width="64"
                height="40"
                decoding="async"
                data-nimg="1"
                src={logo_light}
                style={{
                  color: "transparent",
                  objectFit: "contain",
                  maxWidth: "64px",
                  height: "auto",
                }}
              /> */}
            </div>
            <span className="hidden md:hidden lg:block text-base font-gtAlpinaLight opacity-0 group-hover:opacity-100 transition-opacity duration-700 ml-4">
              From seed to win.
            </span>
          </a>
          <div className="font-gtAmericaMonoLight font-light flex flex-row gap-6 lg:gap-10">
            <a className="relative cursor-pointer hidden lg:block" href="/">
              <span>Exit</span>
              <span className="absolute -bottom-1 left-0 w-0 h-[1px] hover:w-full bg-DentalBlue dark:bg-offBlue transition-all duration-200 ChatPage_line__loFXK"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="flex max-w-[1344px] lg:h-[88vh] m-auto">
        <div className="w-full lg:w-1/2 lg:mr-16 mb-15 pb-44 overflow-y-scroll mt-8">
          <div className="flex flex-col">
            <div className="flex flex-col gap-3 lg:gap-5 grow">
              <div className="flex flex-col gap-4 px-6">
                <div className="flex flex-row gap-4">
                  <div>
                    <div className="mt-1 h-4 w-4 bg-DentalGreen"></div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div>
                      Hi, I'm DentalChat. I'm an AI model enriched with 5 years'
                      worth of Dental essays and podcasts. What are you working
                      on? Or start with an example:
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex-col lg:grid lg:grid-cols-2 gap-3 lg:gap-4 pb-10 lg:pb-0 px-3"
                style={{ opacity: 1 }}
              >
                <button className="h-20 lg:h-auto text-left lg:py-3 px-3 bg-offBlue/10">
                  What does Dental invest in?
                </button>
                <button className="h-20 lg:h-auto text-left lg:py-3 px-3 bg-offBlue/10">
                  What are Network Effects?
                </button>
                <button className="h-20 lg:h-auto text-left lg:py-3 px-3 bg-offBlue/10">
                  How can I make my startup defensible?
                </button>
                <button className="h-20 lg:h-auto text-left lg:py-3 px-3 bg-offBlue/10">
                  How do I grow my gaming company?
                </button>
                <button className="h-20 lg:h-auto text-left lg:py-3 px-3 bg-offBlue/10">
                  Pricing strategies for my B2B SaaS startup.
                </button>
                <button className="h-20 lg:h-auto text-left lg:py-3 px-3 bg-offBlue/10">
                  What makes a strong startup CEO?
                </button>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0">
            <div className="mx-6 h-16 bg-gradient-to-t from-midnightDark"></div>
            <div className="px-6 lg:px-0 bg-midnightDark">
              <div className="flex flex-col lg:max-w-[640px] pb-4 lg:pb-12 mx-auto gap-8">
                <div className="flex flex-col gap-2">
                  <div className="flex border border-offBlue/25 focus-within:border-offBlue py-3 pl-4 relative">
                    <textarea
                      className="p-0 w-full bg-midnightDark overflow-y resize-none outline-none TextArea_customScrollbar__XyddQ"
                      placeholder="Enter a prompt."
                      style={{ height: "28px" }}
                    ></textarea>
                    <div className="text-offBlue/50 self-end pr-2">
                      <button className="cursor-pointer h-8 w-8 flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-offBlue"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="font-gtAmericaMonoLight text-xs text-offBlue/75">
                  DentalChat is an experimental tool in Beta and may be inaccurate
                  at times.{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="underline underline-offset-4"
                    href="mailto:qed@Dental.com"
                  >
                    Your feedback
                  </a>{" "}
                  will help us refine it.{" "}
                  <a className="underline underline-offset-2 cursor-pointer">
                    Read Disclaimers
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 overflow-y-scroll flex flex-col gap-6 lg:mt-6 lg:pr-4 hidden lg:flex">
          <div className="font-bold">Top Sources </div>
          <div className="flex flex-col gap-6">
            <div>
              <div style={{ position: "relative" }}>
                <div>
                  <div
                    className="scene-container"
                    style={{
                      position: "relative",
                      width: "640px",
                      height: "550px",
                    }}
                  >
                    <div className="scene-nav-info" style={{ display: "none" }}>
                      Left-click: rotate, Mouse-wheel/middle-click: zoom,
                      Right-click: pan
                    </div>
                    <div
                      className="scene-tooltip"
                      style={{
                        top: "13.6667px",
                        left: "10.8177px",
                        transform: "translate(-1.69026%, 21px)",
                      }}
                    ></div>
                    <MyCanvasComponent/>
                    {/* <canvas data-engine="three.js r155" width="1280" height="1100" style={{display: "block", touchAction: "none", width: "640px", height: "550px"}}></canvas> */}
                  </div>
                </div>
                <div className="graph-info-msg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbotnew;
