export const isValidValue = (value: any) => {
    return (value !== undefined && value !== null);
}

export const isValidString = (value: any) => {
    return isValidValue(value) && value !== '';
}

export const trimObjectProperties = (obj: any) => {
    const trimmedObject: any = {};

    for (const key in obj) {
        if (typeof obj[key] === 'string') {
            trimmedObject[key] = obj[key].trim();
        } else {
            trimmedObject[key] = obj[key];
        }
    }

    return trimmedObject;
};