import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
import "../dashboard/dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Loader from "../../components/ui/loader/loader.components";
import { Chart } from "primereact/chart";
import { ChartData, ChartOptions } from 'chart.js';

const Dashboard = () => {
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color') || '#495057';
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary') || '#6c757d';
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border') || '#dfe7ef';

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const delayedFetch = () => {
      setIsadding(true);
      // Add a delay of 500ms before making the API call
      timer = setTimeout(() => {
        // add api functions here
        setIsadding(false);
      }, 500);
    };
    delayedFetch();
    return () => clearTimeout(timer);
  }, []);

const barData: ChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Appointments',
            backgroundColor: documentStyle.getPropertyValue('--primary-500') || '#6366f1',
            borderColor: documentStyle.getPropertyValue('--primary-500') || '#6366f1',
            data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
            label: 'Canceled',
            backgroundColor: documentStyle.getPropertyValue('--primary-200') || '#bcbdf9',
            borderColor: documentStyle.getPropertyValue('--primary-200') || '#bcbdf9',
            data: [28, 48, 40, 19, 86, 27, 90]
        }
    ]
};

const barOptions: ChartOptions = {
    plugins: {
        legend: {
            labels: {
                color: textColor
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: textColorSecondary,
                font: {
                    weight: '500'
                }
            },
            grid: {
                display: false
            },
            border: {
                display: false
            }
        },
        y: {
            ticks: {
                color: textColorSecondary
            },
            grid: {
                color: surfaceBorder
            },
            border: {
                display: false
            }
        }
    }
};

const lineData: ChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'New Patients',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: documentStyle.getPropertyValue('--primary-500') || '#6366f1',
            borderColor: documentStyle.getPropertyValue('--primary-500') || '#6366f1',
            tension: 0.4
        },
        {
            label: 'Patients',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: documentStyle.getPropertyValue('--primary-200') || '#bcbdf9',
            borderColor: documentStyle.getPropertyValue('--primary-200') || '#bcbdf9',
            tension: 0.4
        }
    ]
};

const lineOptions: ChartOptions = {
    plugins: {
        legend: {
            labels: {
                color: textColor
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: textColorSecondary
            },
            grid: {
                color: surfaceBorder
            },
            border: {
                display: false
            }
        },
        y: {
            ticks: {
                color: textColorSecondary
            },
            grid: {
                color: surfaceBorder
            },
            border: {
                display: false
            }
        }
    }
};




  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}

        <div>
          {/* <Navbar /> */}
          <section className="page-content">
            <div className="ds-content-table">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page_title d-sm-flex">
                      <div>
                        <div
                          className="fs-20 ds-text-link-primary"
                          style={{ fontFamily: "Roboto", fontWeight: "800" }}
                        >
                          {""} Hello
                        </div>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            color: "#aeaeb5",
                          }}
                        >
                          {" "}
                          Lets check your status today !{" "}
                        </span>
                        {/* <div className="row mb-4"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="row">
                  <div className="row">
                    {/* <div className="row row-deck ds-dashboard-deck"> */}

                    <div className="col-12 lg:col-12 xl:col-3">
                      <div className="card dashboard-card mb-0 p-3">
                        <div className="flex justify-content-between mb-3">
                          <div>
                            <span className="block text-500 font-medium mb-3">
                              Patients
                            </span>
                            <div className="text-900 font-medium text-xl">
                              100
                            </div>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                          >
                            <i className="pi pi-users text-blue-500 text-xl"></i>
                          </div>
                        </div>
                        <span className="text-green-500 font-medium">
                          40 new{" "}
                        </span>
                        <span className="text-500">since last visit</span>
                      </div>
                    </div>

                    <div className="col-12 lg:col-12 xl:col-3">
                      <div className="card dashboard-card mb-0 p-3">
                        <div className="flex justify-content-between mb-3">
                          <div>
                            <span className="block text-500 font-medium mb-3">
                              Appointments
                            </span>
                            <div className="text-900 font-medium text-xl">
                              80
                            </div>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center bg-orange-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                          >
                            <i className="pi pi-calendar-times text-orange-500 text-xl"></i>
                          </div>
                        </div>
                        <span className="text-green-500 font-medium">
                          30 new{" "}
                        </span>
                        <span className="text-500">since last visit</span>
                      </div>
                    </div>

                    <div className="col-12 lg:col-12 xl:col-3">
                      <div className="card dashboard-card mb-0 p-3">
                        <div className="flex justify-content-between mb-3">
                          <div>
                            <span className="block text-500 font-medium mb-3">
                              Staff
                            </span>
                            <div className="text-900 font-medium text-xl">
                              20
                            </div>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                          >
                            <i className="fa-solid fa-user-nurse text-cyan-500 text-xl"></i>
                          </div>
                        </div>
                        <span className="text-green-500 font-medium">
                          2 new{" "}
                        </span>
                        <span className="text-500">since last visit</span>
                      </div>
                    </div>

                    <div className="col-12 lg:col-12 xl:col-3">
                      <div className="card dashboard-card mb-0 p-3">
                        <div className="flex justify-content-between mb-3">
                          <div>
                            <span className="block text-500 font-medium mb-3">
                              Invoices
                            </span>
                            <div className="text-900 font-medium text-xl">
                              120
                            </div>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center bg-purple-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                          >
                            <i className="pi pi-file-pdf text-purple-500 text-xl"></i>
                          </div>
                        </div>
                        <span className="text-green-500 font-medium">
                          12 new{" "}
                        </span>
                        <span className="text-500">since last visit</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 row-deck">
                <div className="grid p-fluid">
            <div className="col-12 xl:col-6">
                <div className="card dashboard-card p-3">
                    <h5>Patients</h5>
                    <Chart type="line" data={lineData} options={lineOptions}></Chart>
                </div>
            </div>
            <div className="col-12 xl:col-6">
                <div className="card dashboard-card p-3">
                    <h5>Appoinments</h5>
                    <Chart type="bar" data={barData} options={barOptions}></Chart>
                </div>
            </div>
        </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
