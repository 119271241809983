import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import "../login/login.css";
// import { signIn } from "../../api/auth.api";
import Loader from "../../components/ui/loader/loader.components";
import logo from "../../assets/images/logo.svg";
import logo_light from "../../assets/images/logo_light.svg";
import logo_ban from "../../assets/images/login_ban.png";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Carousel, CarouselResponsiveOption } from "primereact/carousel";
import slider1 from "../../assets/images/slider1.svg";
import { Button } from 'primereact/button';
import HttpInstance from "../../api/http.api";
import { dentistLogin } from "../../api/auth.api";

export default function Loginnew() {

  const toast = useRef<Toast>(null);
  const [visible, setVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const [value, setValue] = useState<any>({ username: '', password: '' });

  const navigate = useNavigate();

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  // Creating schema
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string().required("Password is a required field"),
  });

  const header = (
    <div className="logo">
      <img alt="Logo" width={300} height={80} src={logo} />
    </div>
  );

  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      {/* <p className="privacy">
           By logging in or Signing up, you agree to the cb user Agreement Privacy policy and Cookie policy
      </p> */}
    </div>
  );

  useEffect(() => { });

  const handleLogin = async () => {

    await dentistLogin(value).then((response) => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('id', response.data.id);
      navigate('/dashboard');
    }).catch((err) => {
      console.log('login error', err);
    })
  }

  const handleKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // Trigger form submission when Enter key is pressed
      handleLogin();
    }
  };

  return (
    <>
      <div className="container-fluid lh-100">
        <Toast ref={toast} />
        {!visible ? "" : <Loader />}

        <div className="row no-margin h-100">

          <div className="col-sm-12 no-padding login-box h-100 d-flex justify-content-center pr-0 pl-0">
            <div className="row no-margin w-100">
              <div className="col-lg-6 col-md-6 box-de">
                <div className="small-logo">
                  <img src={logo} style={{ width: "150px" }} />
                </div>
                <div className="ditk-inf sup-oi">
                <div className="d-flex justify-content-center">
                  {/* <ul>
                               <li><i className="fab fa-facebook-f"></i></li>
                               <li><i className="fab fa-twitter"></i></li>
                               <li><i className="fab fa-linkedin-in"></i></li>
                           </ul> */}
                  <img src={logo_ban} className="login-ban" />
                </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 log-det">
                <div className="card login-card d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="row">
                  <div className="login-logo">
                      <img src={logo_light} style={{ width: "150px" }} />
                    </div>
                    <h5>Welcome Back</h5>

                    <div className="row">
                      <p className="small-info">or use your email account</p>
                    </div>

                    <div className="text-box-cont">
                      <div className="field col-12 md:col-12">
                        <span className="p-float-label p-input-icon-right">
                          <i className="bi bi-envelope"></i>
                          <InputText
                            id="email"
                            value={value.username}
                            onKeyPress={handleKeyPress}
                            type="text" className="ds-login-input" onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setValue({ ...value, username: e.target.value })
                            } />
                          <label htmlFor="email">Email</label>
                        </span>
                      </div>
                      <div className="field col-12 md:col-12">
                        <span className="p-float-label">
                          <Password
                            id="password"
                            value={value.password}
                            className="ds-login-input"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setValue({ ...value, password: e.target.value })
                            }
                            toggleMask
                            feedback={false}
                            onKeyPress={handleKeyPress}
                          />
                          <label htmlFor="password">Password</label>
                        </span>
                      </div>

                      <div className="d-flex justify-content-center mb-3">

                        <Button
                          label="Login"
                          onClick={() => handleLogin()}
                          style={{
                            backgroundColor: "#87dfd6",
                            border: "1px solid #87dfd6", width: "130px", height: "45px",
                            color: "#000", fontWeight: 600
                          }}
                          severity="success"
                          rounded
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}