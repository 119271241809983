import React, { useEffect, useRef, useState } from "react";

import "./comingsoon.page.css";

export default function Commingsoon() {

return (
    <>
          <main>

        <div>
          {/* <Navbar /> */}
          <section className="page-content">

          <div className="container comminsoon-container">
			<div className="wrapper">
				<div className="content">
					<h1>We're Coming Soon</h1>
					<p>Perfect and awesome to present your future product or service.<br></br>Hooking audience attention is all in the opener.</p>
					
					<a href="/" className="btn2">Back to Home</a>
				</div> 
			</div> 
		</div> 
        </section>
       </div>
       </main>
            
            
            </>
)
}