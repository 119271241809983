import React, { useEffect, useRef } from 'react';

const MyCanvasComponent: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const PI = Math.PI;
  const RATE = 1.005;
  const DI = Math.max(640, 550);
  const RAD = DI * 0.5;
  const R = Math.sqrt(0.5);
  const YEAR = 50;
  const AGE = 25;
  let points: number[][] = [];

  useEffect(() => {
    const cvs = canvasRef.current;
    const ctx = cvs?.getContext('2d');

    if (ctx && cvs) {
      cvs.width = DI;
      cvs.height = DI;

      for (let i = 0; i < 750; i++) setTimeout(step, 0);

      runner();
    }

    function step() {
      points = points.map(degradePoint);

      points.push(initialPoint());

      if (ctx && cvs) {
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, DI, DI);

        for (let i = points.length - 1; i >= 0; i--) {
          let [x, y, a, r, g] = points[i];
          let year = Math.ceil(g / YEAR);
          if (year < AGE) {
            let rat = year / AGE;
            let rad = rat * 2;
            if (ctx) {
              ctx.fillStyle = `rgba(255, 255, 255, ${rat * 0.7 + 0.3})`;
              ctx.beginPath();
              ctx.arc(x * DI + RAD, y * DI + RAD, rad, 0, 2 * PI);
              ctx.fill();
            }
          } else points.splice(i, 1);
        }
      }
    }

    function runner() {
      step();
      requestAnimationFrame(runner);
    }

    function initialPoint(): number[] {
      let angle = Math.random() * (PI * 2);
      return [0.5, 0.5, angle, 0.01, 0];
    }

    function degradePoint([x, y, angle, r, gen]: number[]): number[] {
      r *= RATE;
      angle += 0.001;
      return [Math.cos(angle) * r, Math.sin(angle) * r, angle, r, gen + 1];
    }
  }, []);

  return <canvas ref={canvasRef} width= "640"  height= "550"/>;
};

export default MyCanvasComponent;
