import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";

import "./Staff.css";
import AppDataTable from "../../components/app-table/AppDataTable";
import { FilterElementEnum, TableActionEnum, appIcons, fieldName, labelText } from "../../utils/text-constants";
import { Button } from "primereact/button";
import Dialog from "../../components/dialog/Dialog";
import { addStaff, deleteStaff, editStaff, getAllStaff } from "../../api/staff.api";
import { successAlert } from "../../utils/alerts/alert";


const Staff = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>('');
  const [staff, setStaff] = useState<any[]>([]);
  const [staffCopy, setStaffCopy] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    clinicRole: '',
    experience: '',
    clinicID: ''
  });

  useEffect(() => {
    getAllStaffFun();
  }, []);

  const getAllStaffFun = async () => {
    await getAllStaff().then((response: any) => {
      const staffData = response?.data.map((staff: any) => ({
        ...staff,
        Name: `${staff.firstName} ${staff.lastName}`,
      }));
      setStaff(staffData);
      setStaffCopy(staffData);
    }).catch((err: any) => {
      console.log('staff get error', err);
    })
  }


  const onRowClick = (rowData: any) => {
    // alert('Row clicked')
  }

  const onStaffEdit1 = async (rowData: any) => {
    setIsVisible(true);
    setIsEdit(true);
    setEditId(rowData._id);
    setFormData({
      firstName: rowData.firstName,
      lastName: rowData.lastName,
      username: rowData.username,
      password: rowData.password,
      clinicRole: rowData.clinicRole,
      experience: rowData.experience,
      clinicID: rowData.clinicID
    }
    )
  }

  const onStaffDelete1 = async (rowData: any) => {
    await deleteStaff(rowData._id).then((response: any) => {
      getAllStaffFun();
      successAlert({ title: 'Success', text: `Staff deleted successfully.`, icon: 'success' })
    }).catch((err: any) => {
      console.log('error in deleting staff', err);
    })
  }

  //table congig
  const tableConfig: any = {
    onRowClick: onRowClick,
    columnConfig: [
      {
        columnName: 'Name',
        columnHeader: 'Name',
        isSortable: true,
        isFilterable: true,
        filterPlaceholder: 'Enter name',
        filterType: FilterElementEnum.InputText,
        filterOptions: '',
        filterOptionLabel: '',
        filterOptionValue: '',
      },
      {
        columnName: 'username',
        columnHeader: 'User Name',
        isSortable: true,
        isFilterable: true,
        filterPlaceholder: 'Enter username',
        filterType: FilterElementEnum.InputText,
        filterOptions: '',
        filterOptionLabel: '',
        filterOptionValue: '',
      },
      {
        columnName: 'clinicRole',
        columnHeader: 'Clinic Role',
        isSortable: true,
        isFilterable: true,
        filterPlaceholder: 'Enter clinicRole',
        filterType: FilterElementEnum.InputText,
        filterOptions: '',
        filterOptionLabel: '',
        filterOptionValue: '',
      },
      {
        columnName: 'experience',
        columnHeader: 'Experince',
        isSortable: true,
        isFilterable: true,
        filterPlaceholder: 'Enter experience',
        filterType: FilterElementEnum.InputText,
        filterOptions: '',
        filterOptionLabel: '',
        filterOptionValue: '',
      },
      {
        columnName: fieldName.actions,
        columnHeader: labelText.actions,
        isSortable: false,
        isFilterable: false,
        actions: [
          { action: TableActionEnum.Edit, actionMethod: onStaffEdit1, actionIcon: appIcons.edit, tooltip: labelText.edit },
          { action: TableActionEnum.Archive, actionMethod: onStaffDelete1, actionIcon: appIcons.delete, tooltip: labelText.delete },
        ]
      },
    ]
  };

  const onStaffAdd = async () => {
    if (!isEdit) {
      await addStaff(formData).then((response: any) => {
        successAlert({ title: 'Success', text: `Staff added successfully.`, icon: 'success' });
        setIsVisible(false);
        setIsEdit(false);
        setFormData({
          firstName: '',
          lastName: '',
          username: '',
          password: '',
          clinicRole: '',
          experience: '',
          clinicID: ''
        })
        getAllStaffFun();
      }).catch((err: any) => {
        console.log('Staff add error', err)
      })
    } else {
      await editStaff(formData, editId).then((response: any) => {
        successAlert({ title: 'Success', text: `Staff deleted successfully.`, icon: 'success' })
        setIsVisible(false);
        setFormData({
          firstName: '',
          lastName: '',
          username: '',
          password: '',
          clinicRole: '',
          experience: '',
          clinicID: ''
        })
        getAllStaffFun();
      }).catch((err: any) => {
        console.log('staff edit error', err);
      })
    }
  }

  const handleGlobalFilterChange = (e: any) => {
    const filteredData = staffCopy.filter(item => {
      // Customize this condition based on your requirements
      return Object.values(item).some((value: any) =>
        value.toString().toLowerCase().includes(e.target.value.toLowerCase())
      );
    });

    setStaff(filteredData);
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <section className="page-content">
      <div className="layout-content">

        {/* Staff add start*/}
        <Dialog
          header={isEdit ? "Editing a Staff" : "Adding a new Staff"}
          visible={isVisible}
          onHide={() => { setIsVisible(false); setIsEdit(false) }}
          handleCancel={() => { setIsVisible(false); setIsEdit(false) }}
          handleFooterSubmit={() => onStaffAdd()}
        >
          <div className="form-container">
            <form>
              <div className="form-group">
                <label>First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Enter first name"
                />
              </div>

              <div className="form-group">
                <label>Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Enter last name"
                />
              </div>

              <div className="form-group">
                <label>Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Enter username"
                />
              </div>

              <div className="form-group">
                <label>Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Enter password"
                />
              </div>

              <div className="form-group">
                <label>Clinic Role:</label>
                <input
                  type="text"
                  id="clinicRole"
                  name="clinicRole"
                  value={formData.clinicRole}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Enter clinic role"
                />
              </div>

              <div className="form-group">
                <label>Experience:</label>
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Enter experince"
                />
              </div>

              <div className="form-group">
                <label>Clinic ID:</label>
                <select
                  id="clinicID"
                  name="clinicID"
                  value={formData.clinicID}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Select clinic"
                >
                  <option value="" disabled>Select Clinic ID</option>
                  <option value="654f61a4701cb6528b9e335e">Clinic 1</option>
                  <option value="654f61a4701cb6528b9e335e">Clinic 2</option>
                  {/* Add more clinic options as needed */}
                </select>
              </div>
            </form>
          </div>
        </Dialog>
        {/* Staff add end*/}
        <div className="d-flex justify-content-between p-3">
            <span className="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
              {/* <i className="pi pi-search pt-1"></i>
              <input
                className="p-inputtext p-component w-full w-full"
                placeholder="Search Staff"
                data-pc-name="inputtext"
                data-pc-section="root"
                onChange={(e: any) => handleGlobalFilterChange(e)}
              /> */}
            </span>
            <Button
              label="Add Staff"
              icon="pi pi-user-plus"
              onClick={() => setIsVisible(true)}
              style={{
                backgroundColor: "#87dfd6",
                border: "1px solid #87dfd6",
                color: "#000", fontWeight: 600
              }}
              severity="success"
            />
          </div>
        <div
          className="p-datatable p-component p-datatable-responsive-scroll"
          data-scrollselectors=".p-datatable-wrapper"
          data-pc-name="datatable"
          data-pc-section="root"
        >

        </div>
        <Card title="Staff" className='staff-card'>
          <div className='card-body'>
            <AppDataTable inputTableConfig={tableConfig} inputData={staff} />
          </div>
        </Card>
      </div>
    </section>
  );
};

export default Staff;
