import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../../assets/images/logo_light.svg";

const Navbar = () => {

  const navigate = useNavigate();

  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  console.log(splitLocation);

  const logout = () => {
    localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
    navigate('/');
  };

  return (
    <div>
      <aside
        aria-hidden="true"
        aria-labelledby="offcanvasNavbarLabel"
        className="cb_left_nav d-flex flex-column flex-shrink-0 offcanvas offcanvas-start"
        id="offcanvasNavbar"
        tabIndex={-1}
      >
        <p className="fs-20 fw-700 ds-logo-link" onClick={() => navigate("/dashboard")}>
          <img src={logo} alt="cb Logo" className="site-logo" />
        </p>
        <nav className="nav flex-column cb_nav_links mb-auto">
          <p
            className={
              splitLocation[1] === "dashboard" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate("/dashboard")}
          >
            <i className="pi pi-home" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Dashboard</span>
          </p>
          <p
            className={
              splitLocation[1] === "patients-list" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate("/patients-list")}
          >
            <i className="bi bi-people" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Patients List</span>
          </p>
          <p
            className={
              splitLocation[1] === "appointment-calender" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate("/appointment-calender")}
          >
            <i className="bi bi-calendar-event" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Appointments Calender</span>
          </p>
          <p
            className={
              splitLocation[1] === "staff-management" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate("/staff-management")}
          >
            <i className="bi bi-people" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Staff Management</span>
          </p>
          <p
            className={
              splitLocation[1] === "dental-charting" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate('/dental-charting')}
          >
            <i className="fa-solid fa-tooth" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Dental charting</span>
          </p>
          <p
            className={
              splitLocation[1] === "invoice-generation" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate("/invoice-generation")}
          >
            <i className="fas fa-file-invoice" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Invoice generation</span>
          </p>
          <p
            className={
              splitLocation[1] === "statistics" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate("/statistics")}
          >
            <i className="fas fa-file-invoice" style={{ fontSize: '1rem' }}></i>
            <span className="sidenav-data">Statistics</span>
          </p>

        </nav>
        <nav className="nav cb_nav_links">
          <p
             className={
              splitLocation[1] === "profile" ? "nav-link active" : "nav-link"
            }
            onClick={() => navigate('/profile')}
          >
            <i className="bi bi-person-circle" style={{ fontSize: '1rem' }}></i>
            <span>Profile</span>
          </p>
        </nav>
        <nav className="nav cb_nav_links">
          <p
            className="nav-link ds-nav-bottom-link"
            onClick={logout}
          >
            <i className="pi pi-sign-out" style={{ fontSize: '1rem' }}></i>
            <span>Log out</span>
          </p>
        </nav>
      </aside>
    </div>
  );
};
export default Navbar;
