
import { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { DataTable, DataTableFilterEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

import './AppDataTable.css';
import { isValidValue } from '../../utils/common-functions';
import { FilterElementEnum, genericText, labelText } from '../../utils/text-constants';

const AppDataTable: React.FC<any> = ({ inputData, inputTableConfig }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        setLoading(true)
        setTableData(inputData);
        setLoading(false);
    }, [inputData]);

    const onFilterDropdownChange = (event: any) => {
        event.filterApplyCallback(event.target.value);
    }

    const onFilterInputChange = (event: any, e: any) => {
        event.filterApplyCallback(e.target.value);
    }

    const columnFilter = (event: any, colConfig: any) => {
        if (colConfig.filterType === FilterElementEnum.DropDown) {

            return (
                <Dropdown
                    onChange={(event) => onFilterDropdownChange(event)}
                    value={isValidValue(event.value) ? event.value : ''}
                    options={colConfig.filterOptions}
                    optionLabel={colConfig.filterOptionLabel as string}
                    placeholder={colConfig.filterPlaceholder as string}
                />
            )
        }
        else {

            return (
                <InputText
                    value={isValidValue(event.value) ? event.value : ''}
                    onChange={(e: any) => onFilterInputChange(event, e)}
                    placeholder={colConfig.filterPlaceholder}
                    className="p-column-filter filter-input" />
            )
        }
    }

    const onFilter = (event: DataTableFilterEvent) => {
    }

    const representativeBodyTemplate = (actions: any, rowData: any) => {

        return (
            actions.map((action: any, index: number) => {

                return (
                    <span key={index}>
                        <button
                            className={action.actionIcon + genericText.classSeparator + 'actionIcon fs-16'}
                            onClick={() => action.actionMethod(rowData)}
                            title={action.tooltip}
                            style={{ border: 'none', background: 'none' }}
                        ></button>
                    </span>
                )
            })
        );
    };

    const dynamicColumns = inputTableConfig?.columnConfig?.map((colConfig: any, index: number) => {
        const columnKey = colConfig.columnName;  // Use a unique identifier as the key

        if (colConfig.columnHeader === labelText.actions) {
            return (
                <Column
                    key={columnKey}
                    field={colConfig.columnName}
                    header={colConfig.columnHeader}
                    sortable={colConfig.isSortable}
                    className="columnActionTemplate"
                    body={(rowData) => representativeBodyTemplate(colConfig.actions as any, rowData)}
                />
            )
        }

        return (
            <Column
                key={columnKey}
                field={colConfig.columnName}
                header={colConfig.columnHeader}
                sortable={colConfig.isSortable}
                filter={colConfig.isFilterable}
                className="columnTemplate"
                showFilterMenu={false}
                showClearButton={false}
                filterPlaceholder={colConfig.filterPlaceholder}
                filterElement={(e) => columnFilter(e, colConfig)}
            />
        )
    });

    const customRowClass = () => {
        return (inputTableConfig.onRowClickFn ? 'custom-row-hover-class' : "custom-row-class") + " custom-row-height";
    };

    return (
        <div className="">
            <DataTable value={tableData} paginator rows={10}
                dataKey="id"
                filterDisplay="row"
                loading={loading}
                emptyMessage="No data found."
                size='small'
                selectionMode="single"
                scrollable
                className='table-height'
                scrollHeight='500px'
                removableSort={false}
                sortMode={"single"}
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowSelect={(event) => { inputTableConfig?.onRowClick(event); }}
                stripedRows
                // onFilter={(e: DataTableFilterEvent) => { onFilter(e); }}
                rowClassName={customRowClass}
            >
                {dynamicColumns}
            </DataTable>
        </div>
    );
}

export default AppDataTable;