import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
import "../statistics/Statistics.page.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Loader from "../../components/ui/loader/loader.components";
import { Chart } from "primereact/chart";
import {tabledata,toppagedata,topvisitorsData,mapvisitorsData, topCountriesData} from "../statistics/statisticsdata"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
interface User {
    id: string;
    time: string;
    visitors: number;
    visits: number;
}
interface PageData {
    id: number;
    Title: string;
    link: string;
    Visits: number;
  }
interface ColumnMeta {
    field: string;
    header: string;
}



const Statistics = () => {
   const navigate = useNavigate(); 

  const [isAdding, setIsadding] = useState(false); // Loader state..

  const [hitchartData, setHitChartData] = useState({});
  const [hitchartOptions, setHitChartOptions] = useState({});
  const [browserOptions, setBrowserOptions] = useState({});
  const [platformsOptions, setPlatformsOptions] = useState({});
  const [devicesOptions, setDevicesOptions] = useState({});
  const [topcountriesData,setTopcountriesData]=useState({})
  const [getVisitorsData,setGetVisitorsData] = useState({});
  const [getMostSearchedData,setGetMostSearchedData] = useState({});
  const [topPages,setToppages]= useState({});
  const [topVistors,setTopvisitors]= useState({});
  const [visitorsLocationData,setVisitorsLocationData] = useState({});
    const [userstable, setUserstable] = useState<User[]>([]);
    const columns: ColumnMeta[] = [
        {field: 'date', header: 'Time'},
        {field: 'visitorCount', header: 'Visitors'},
        {field: 'visitCount', header: 'Visits'}
    ];
    const [toppagetable, setTopPagetable] = useState<PageData[]>([]);
    const toppagecolumns: ColumnMeta[] = [
        { field: 'id', header: '' },
        { field: 'Title', header: 'Page Name' },
        { field: 'link', header: 'Page Link' },
        { field: 'Visits', header: 'Visits' },
    ];


  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

 

  const browserData = {
    labels: ['A', 'B', 'C'],
    datasets: [
        {
            data: [540, 325, 702],
            backgroundColor: [
                documentStyle.getPropertyValue('--blue-500'), 
                documentStyle.getPropertyValue('--yellow-500'), 
                documentStyle.getPropertyValue('--green-500')
            ],
            hoverBackgroundColor: [
                documentStyle.getPropertyValue('--blue-400'), 
                documentStyle.getPropertyValue('--yellow-400'), 
                documentStyle.getPropertyValue('--green-400')
            ]
        }
    ]
}

const platformsData = {
    labels: ['A', 'B', 'C'],
    datasets: [
        {
            data: [540, 325, 702],
            backgroundColor: [
                documentStyle.getPropertyValue('--blue-500'), 
                documentStyle.getPropertyValue('--yellow-500'), 
                documentStyle.getPropertyValue('--green-500')
            ],
            hoverBackgroundColor: [
                documentStyle.getPropertyValue('--blue-400'), 
                documentStyle.getPropertyValue('--yellow-400'), 
                documentStyle.getPropertyValue('--green-400')
            ]
        }
    ]
}


const devicesData = {
    labels: ['A', 'B', 'C'],
    datasets: [
        {
            data: [540, 325, 702],
            backgroundColor: [
                documentStyle.getPropertyValue('--blue-500'), 
                documentStyle.getPropertyValue('--yellow-500'), 
                documentStyle.getPropertyValue('--green-500')
            ],
            hoverBackgroundColor: [
                documentStyle.getPropertyValue('--blue-400'), 
                documentStyle.getPropertyValue('--yellow-400'), 
                documentStyle.getPropertyValue('--green-400')
            ]
        }
    ]
}
  const hitoptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
          legend: {
              labels: {
                  color: textColor
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder
              }
          },
          y: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder
              }
          }
      },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "300px";
    },
  }


  const browseroptions = {
    plugins: {
        legend: {
            labels: {
                usePointStyle: true,
                fontColor: '#495057'
            }
        }
    }
  };


  const platformsoptions = {
    plugins: {
        legend: {
            labels: {
                usePointStyle: true,
                fontColor: '#495057'
            }
        }
    }
  };


  const devicesoptions = {
    plugins: {
        legend: {
            labels: {
                usePointStyle: true,
                fontColor: '#495057'
            }
        }
    }
  };


  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}

        <div>
          <Navbar />
          <section className="page-content">
            <div className="ds-content-table">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                  <div className="page_title d-sm-flex">
              <div className="d-flex align-items-center FE-heading-nav">
                <a href="/dashboard" className="d-flex"> 
                <span className="home-span">Dashboard</span></a>
                <i className="pi pi-angle-right" style={{color: "rgb(194, 194, 194)", fontSize: "12px"}}></i>
                <span className="event-span">Statistics</span></div>
              </div>


                    <div className="grid mt-4">
                        <div className="col-4 md:col-4 lg:col-4 xl:col-4">
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Summary
                                </div>
                                {/* {Array.isArray(getVisitorsData) && getVisitorsData.length > 0 ? ( */}
                                <DataTable value={tabledata}>
                                    <Column field="time" header="Time" />
                                    <Column field="visitors" header="Visitors" />
                                    <Column field="visits" header="Visits" />
                                </DataTable>
                                {/* ) : (
                                  <p>No data available</p>
                                )} */}
                                
                            </div>
                            <br></br>
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Top Browsers
                                </div>
                                <Chart type="pie" data={browserData} options={browserOptions} />
                            </div>
                            <br></br>
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Top Platforms
                                </div>
                                <Chart type="pie" data={platformsData} options={platformsOptions} />
                            </div>
                            <br></br>
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Top Devices
                                </div>
                                <Chart type="pie" data={devicesData} options={devicesOptions} />
                            </div>
                            <br></br>
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Top Countries
                                </div>
                                <br></br>
                                
                                {/* {Array.isArray(topcountriesData) && topcountriesData.length > 0 ? ( */}
                                <DataTable value={topCountriesData}>
                                    <Column field="id" header="" />
                                    <Column field="country" header="Country" />
                                    <Column field="visitors" header="Visitors" />
                                </DataTable>
                                {/* ) : (
                                  <p>No data available</p>
                                )} */}
                            </div>
                        </div>
                        <div className="col-8 md:col-8 lg:col-8 xl:col-8">
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Hit Statistics
                                </div>
                                <br></br>
                                <Chart type="line" data={hitchartData} options={hitchartOptions} />
                            </div>
                            <br></br>
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Top Pages
                                </div>
                                <br></br>
                                {/* {Array.isArray(topPages) && topPages.length > 0 ? ( */}
                                <DataTable value={toppagedata}>
                                    {toppagecolumns.map((col, i) => (
                                        <Column key={col.field} field={col.field} header={col.header} />
                                    ))}
                                </DataTable>
                                 {/* ) : (
                                  <p>No data available</p>
                                )} */}
                            </div>
                            <br></br>
                            <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                    Top Visitors
                                </div>
                                <br></br>
                                {/* {Array.isArray(topVistors) && topVistors.length > 0 ? ( */}
                                <DataTable value={topvisitorsData}>
                                    {/* <Column field="id" header="#" /> */}
                                    <Column field="visitors" header="Visitors" />
                                    <Column field="country" header="Country" />
                                    <Column field="city" header="City" />
                                    <Column field="ipbrowser" header="IP Browser" />
                                    <Column field="platform" header="Platform" />
                                </DataTable>
                                 {/* ) : (
                                  <p>No data available</p>
                                )} */}
                            </div>
                            <br></br>
                            {/* <div className="card mb-0 dns-chart-card ds-box-card">
                                <div className="fw-600 fs-16 ds-text-link-primary">
                                Most Searched Words
                                </div>
                                <br></br>
                                {Array.isArray(getMostSearchedData) && getMostSearchedData.length > 0 ? (
                                <DataTable value={getMostSearchedData}
                                rows={4}
                                paginator
                                >
                                    <Column field="_id" header="Words" />
                                    <Column field="Count" header="Count" />
                                </DataTable>
                                 ) : (
                                  <p>No data available</p>
                                )}
                            </div> */}

                        </div>


                    </div>



                  </div>
                </div>

                <div className="row mt-2 row-deck">
                  <div className="col-xl-3 col-lg-6 col-md-6"></div>
                </div>
              </div>

              <br></br>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Statistics;
